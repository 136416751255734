import { AppBar, Toolbar, Typography, Grid, Hidden } from '@material-ui/core';
import React from 'react';
import styles from './TopAppBar.module.css';

class TopAppBar extends React.Component<{}, {}> {
    render() {
        return (
            <AppBar position="static" className={styles.AppBar}>
                <Toolbar>
                    <Grid container spacing={3}>
                        <Hidden xsDown>
                            <Grid item xs={2}>
                                <img src="Jemena-logo-sml.png" alt="Jemena Logo" />
                            </Grid>
                            <Grid item xs={9} className={styles.AppBarTitle}>
                                <Typography variant="h5" noWrap>Electricity Connection Cost Estimate</Typography>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item xs={12} className={styles.AppBarTitle}>
                                <Typography variant="h6" noWrap>Electricity Connection Cost Estimate</Typography>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar >
        )
    }
}

export default TopAppBar;