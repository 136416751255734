import React from 'react';
import ReactGA from 'react-ga';
import EstimateForm from './components/EstimateForm';
import TopAppBar from './components/TopAppBar';
import styles from './App.module.css';

const App: React.FC = () => {
  if (process.env.REACT_APP_USE_ANALYTICS === 'TRUE') {
    ReactGA.initialize('UA-15125081-47');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return (
    <div className={styles.MasterContainer}>
      <TopAppBar />
      <EstimateForm />
    </div>
  );
}

export default App;
