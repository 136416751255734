import { Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Hidden, Radio, RadioGroup, Slider, Tooltip, Typography, Modal, Backdrop, Fade, Card, CardContent, CardActions } from '@material-ui/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React from 'react';
import { AllAmpMarks, SmallAmpMarks, TermsofOffer } from '../definitions/constants';
import { IndicativePrice } from '../definitions/Interfaces';
import { ApplicationType, PoleType } from '../definitions/types';
import { calculatePrice } from '../functions/Pricing';
import styles from './EstimateForm.module.css';

interface IEstimateFormProps {

}

interface IEstimateFormState {
    showWelcome: boolean;
    applicationType?: ApplicationType;
    poleType: PoleType;
    numConnections: number;
    supplyAmps: number;
    showComponent: {
        appType: boolean,
        poleType: boolean,
        supply: boolean,
        numConnections: boolean,
    }
    indicativeOffer?: IndicativePrice;
}

class EstimateForm extends React.Component<IEstimateFormProps, IEstimateFormState> {
    appInsights: any;
    constructor(props: IEstimateFormProps) {
        super(props)
        this.state = {
            showWelcome: true,
            numConnections: 1,
            supplyAmps: 100,
            poleType: "LV",
            showComponent: {
                appType: true,
                poleType: false,
                supply: false,
                numConnections: false
            }
        }
    }

    componentDidMount = () => {
        if (process.env.REACT_APP_USE_ANALYTICS === 'TRUE') {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: 'cc0575b1-1591-49d1-a22b-bf9bfdc71414',
                }
            });
            this.appInsights.loadAppInsights();
        }
    }

    showSubQuestions = () => {
        if (this.state.applicationType === "Alteration/Relocation") {
            this.setState({
                showComponent: {
                    appType: true,
                    poleType: true,
                    supply: false,
                    numConnections: false
                }
            })
        }
        else if (this.state.applicationType === "ComNew/IncreasedSupply") {
            this.setState({
                showComponent: {
                    appType: true,
                    poleType: false,
                    supply: true,
                    numConnections: false
                }
            })
        }
        else if (this.state.applicationType === "ResNew/IncreasedSupply") {
            this.setState({
                showComponent: {
                    appType: true,
                    poleType: false,
                    supply: false,
                    numConnections: true
                }
            })
        }
    }

    getIndicativePrice = () => {
        let priceRange = calculatePrice({
            applicationType: this.state.applicationType as ApplicationType,
            poleType: this.state.poleType as PoleType,
            numConnections: this.state.numConnections,
            supplyAmps: this.state.supplyAmps
        });
        if (process.env.REACT_APP_USE_ANALYTICS === 'TRUE') {
            this.appInsights.trackEvent({
                name: "Pricing Request", properties: {
                    RequestDetails: this.state,
                    IndicativeOffer: priceRange,
                }
            });
        }
        this.setState({ indicativeOffer: priceRange });
    }

    priceComponent = () => {
        if (this.state.indicativeOffer) {
            return (
                <Container className={styles.CentreText}>
                    <Typography><b>Price Range:</b> ${this.state.indicativeOffer.priceRange.min} - ${this.state.indicativeOffer.priceRange.max}</Typography>
                    <Typography><b>Application Fee:</b> ${this.state.indicativeOffer.applicationFee}</Typography>
                    <Button variant="contained" color="primary" onClick={this.resetForm} className={styles.RightButton}>Search Again</Button>
                </Container>
            )
        }
        else {
            return (
                <Button variant="contained" color="primary" onClick={this.getIndicativePrice} className={styles.RightButton}>Get Indicative Price</Button>
            )
        }

    }

    resetForm = () => {
        this.setState({
            applicationType: undefined,
            indicativeOffer: undefined,
            poleType: "LV",
            numConnections: 1,
            supplyAmps: 100,
            showComponent: {
                appType: true,
                poleType: false,
                supply: false,
                numConnections: false
            }
        })
    }

    introModal = () => {
        return (
            <Modal
                open={this.state.showWelcome}
                onClose={() => { this.setState({ showWelcome: false }) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={this.state.showWelcome}>
                    <Card className={styles.WelcomeCard}>
                        <CardContent>
                            <Typography color="textPrimary" gutterBottom>
                                Welcome to the Electricity Pricing Estimator
                                </Typography>
                            <Typography component={'span'} color="textSecondary" gutterBottom>
                                {TermsofOffer}
                            </Typography>
                        </CardContent>
                        <CardActions className={styles.RightButton}>
                            <Button size="small" variant="outlined" color="primary" onClick={() => { this.setState({ showWelcome: false }) }}>Get Started</Button>
                        </CardActions>
                    </Card>
                </Fade>
            </Modal>
        )
    }

    render() {
        const ampMarksFull = AllAmpMarks;
        const ampMarksSmall = SmallAmpMarks;
        return (
            <Container className={styles.PrimaryContainer}>
                {this.introModal()}
                <form className={styles.FormBody}>
                    <FormControl>
                        <FormLabel component="legend">Service Type</FormLabel >
                        <RadioGroup
                            value={this.state.applicationType || ''}
                            onChange={(event) => { this.setState({ applicationType: (event.target as HTMLInputElement).value as ApplicationType }, () => { this.showSubQuestions() }) }}>
                            <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="ResNew/IncreasedSupply" control={<Radio />} label="Residential - New or Increased Supply (Where Network Extension is Required)" />
                            <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="ComNew/IncreasedSupply" control={<Radio />} label="Commercial/High Rise - New or Increased Supply" />
                            <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="Alteration/Relocation" control={<Radio />} label="Asset Alteration or Relocation" />
                        </RadioGroup>
                    </FormControl>

                    {this.state.showComponent.poleType ?
                        <FormControl margin="normal">
                            <FormLabel>Pole Type</FormLabel >
                            <FormHelperText>Relocations must be within 5 metres</FormHelperText>
                            <RadioGroup
                                value={this.state.poleType || ''}
                                onChange={(event) => { this.setState({ poleType: (event.target as HTMLInputElement).value as PoleType }) }}>
                                <Tooltip title="Overhead Lines carrying 240 Volts." placement="top-start">
                                    <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="LV" control={<Radio />} label="Single Low Voltage Pole" />
                                </Tooltip>
                                <Tooltip title="Overhead lines carrying over 240V." placement="top-start">
                                    <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="HV" control={<Radio />} label="Single High Voltage Pole" />
                                </Tooltip>
                                <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="PublicLighting" control={<Radio />} label="Single Public Lighting Pole" />
                                <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="LV/HV" control={<Radio />} label="Single Low/High Voltage Pole" />
                                <FormControlLabel disabled={this.state.indicativeOffer !== undefined} value="66/22/LV" control={<Radio />} label="Single 66kV/22kV/Low Voltage Pole" />
                            </RadioGroup>
                        </FormControl>
                        : <span />}

                    {this.state.showComponent.numConnections ?
                        <FormControl margin="normal">
                            <FormLabel>Number of Connections</FormLabel>
                            <FormHelperText>If no network extension is required please refer to Jemena's Connection Charges Guideline.</FormHelperText>
                            <br /><br />
                            <Slider
                                disabled={this.state.indicativeOffer !== undefined}
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={100}
                                track='normal'
                                value={this.state.numConnections}
                                onChange={(event, value) => { this.setState({ numConnections: value as number }) }}
                                valueLabelDisplay="on" />
                        </FormControl>
                        : <span />}

                    {this.state.showComponent.supply ?
                        <FormControl margin="normal">
                            {/* <Box paddingTop={2} width={1}> */}
                            <FormLabel>Supply Required</FormLabel>
                            <FormHelperText>Single Connection</FormHelperText>
                            <br /><br />
                            <Hidden smUp>
                                <Slider
                                    disabled={this.state.indicativeOffer !== undefined}
                                    defaultValue={100}
                                    step={50}
                                    marks={ampMarksSmall}
                                    min={100}
                                    max={1450}
                                    value={this.state.supplyAmps}
                                    onChange={(event, value) => { this.setState({ supplyAmps: value as number }) }}
                                    valueLabelDisplay="on" />
                            </Hidden>
                            <Hidden xsDown>
                                <Slider
                                    disabled={this.state.indicativeOffer !== undefined}
                                    defaultValue={100}
                                    step={50}
                                    marks={ampMarksFull}
                                    min={100}
                                    max={1450}
                                    value={this.state.supplyAmps}
                                    onChange={(event, value) => { this.setState({ supplyAmps: value as number }) }}
                                    valueLabelDisplay="on" />
                            </Hidden>
                            {/* </Box> */}
                        </FormControl>
                        : <span />}
                    {this.state.applicationType ? this.priceComponent() : <span />}
                </form>
            </Container>
        )
    }
}

export default EstimateForm;