import React from "react"
import { Typography } from "@material-ui/core"

export const AllAmpMarks = [
    { value: 100, label: '100A' }, { value: 150 },
    { value: 200, label: '200A' }, { value: 250 },
    { value: 300, label: '300A' }, { value: 350 },
    { value: 400, label: '400A' }, { value: 450 },
    { value: 500, label: '500A' }, { value: 550 },
    { value: 600, label: '600A' }, { value: 650 },
    { value: 700, label: '700A' }, { value: 750 },
    { value: 800, label: '800A' }, { value: 850 },
    { value: 900, label: '900A' }, { value: 950 },
    { value: 1000, label: '1000A' }, { value: 1050 },
    { value: 1100, label: '1100A' }, { value: 1150 },
    { value: 1200, label: '1200A' }, { value: 1250 },
    { value: 1300, label: '1300A' }, { value: 1350 },
    { value: 1400, label: '1400A' }, { value: 1450 }]

export const SmallAmpMarks = [
    { value: 100, label: '100A' }, { value: 150 },
    { value: 200 }, { value: 250 },
    { value: 300, label: '300A' }, { value: 350 },
    { value: 400 }, { value: 450 },
    { value: 500, label: '500A' }, { value: 550 },
    { value: 600 }, { value: 650 },
    { value: 700, label: '700A' }, { value: 750 },
    { value: 800 }, { value: 850 },
    { value: 900, label: '900A' }, { value: 950 },
    { value: 1000 }, { value: 1050 },
    { value: 1100, label: '1100A' }, { value: 1150 },
    { value: 1200 }, { value: 1250 },
    { value: 1300, label: '1300A' }, { value: 1350 },
    { value: 1400 }, { value: 1450 }]

export const TermsofOffer = (
    <div>
        <Typography gutterBottom>The preliminary cost estimate (and information provided on this website) is based on information supplied by you or third parties, without detailed assessment. It is based on our experience of costs for project management, design, construction and supply of materials. This estimate is intended to provide an indicative cost only. It is subject to change due to limited information available and any third party or outside constraints that may affect the price. For example, the latter may include the need for community consultation. These matters may also affect the estimated costs and supply date. While we endeavour to provide an accurate estimate, we give no undertaking as to the accuracy, completeness or up-to-date nature of any information provided. You are responsible for your own independent enquiries and due diligence</Typography>
    </div>
)