import { Application, IndicativePrice } from "../definitions/Interfaces";
import { PoleType } from "../definitions/types";

export function calculatePrice(submissionApp: Application): IndicativePrice {
    if (submissionApp.applicationType === "Alteration/Relocation") {
        return assessAltReloc(submissionApp.poleType)
    }
    else if (submissionApp.applicationType === "ResNew/IncreasedSupply") {
        return assessResConnection(submissionApp.numConnections)
    }
    else if (submissionApp.applicationType === "ComNew/IncreasedSupply") {
        return assessComConnection(submissionApp.supplyAmps)
    }
    else {
        return { priceRange: { min: -1, max: -1 }, applicationFee: -1 }
    }
}

function assessComConnection(supplyAmps: number): IndicativePrice {
    if (supplyAmps <= 100) {
        return {
            priceRange: { min: 10000, max: 40000 },
            applicationFee: 3700
        }
    }
    else if (supplyAmps <= 150) {
        return {
            priceRange: { min: 15000, max: 45000 },
            applicationFee: 3700
        }
    }
    else if (supplyAmps <= 200) {
        return {
            priceRange: { min: 10000, max: 35000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 250) {
        return {
            priceRange: { min: 20000, max: 35000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 300) {
        return {
            priceRange: { min: 20000, max: 35000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 350) {
        return {
            priceRange: { min: 20000, max: 40000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 400) {
        return {
            priceRange: { min: 20000, max: 45000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 450) {
        return {
            priceRange: { min: 20000, max: 45000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 500) {
        return {
            priceRange: { min: 20000, max: 50000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 550) {
        return {
            priceRange: { min: 50000, max: 65000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 600) {
        return {
            priceRange: { min: 55000, max: 70000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 650) {
        return {
            priceRange: { min: 70000, max: 85000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 700) {
        return {
            priceRange: { min: 70000, max: 90000 },
            applicationFee: 4500
        }
    }
    else if (supplyAmps <= 750) {
        return {
            priceRange: { min: 70000, max: 90000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 800) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 850) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 900) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 950) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1000) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1050) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1100) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1150) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1200) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1250) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1300) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1350) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1400) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else if (supplyAmps <= 1450) {
        return {
            priceRange: { min: 150000, max: 180000 },
            applicationFee: 8600
        }
    }
    else {
        return {
            priceRange: { min: -1, max: -1 },
            applicationFee: -1
        }
    }
}

function assessResConnection(numDwellings: number): IndicativePrice {
    if (numDwellings <= 7) {
        return {
            priceRange: { min: 3000, max: 20000 },
            applicationFee: 0
        }
    }
    else if (numDwellings <= 10) {
        return {
            priceRange: { min: 24000, max: 38000 },
            applicationFee: 3700
        }
    }
    else if (numDwellings <= 15) {
        return {
            priceRange: { min: 32000, max: 47000 },
            applicationFee: 3700
        }
    }
    else if (numDwellings <= 20) {
        return {
            priceRange: { min: 44000, max: 60000 },
            applicationFee: 3700
        }
    }
    else if (numDwellings <= 25) {
        return {
            priceRange: { min: 53000, max: 68000 },
            applicationFee: 3700
        }
    }
    else if (numDwellings <= 30) {
        return {
            priceRange: { min: 65000, max: 85000 },
            applicationFee: 3700
        }
    }
    else if (numDwellings <= 35) {
        return {
            priceRange: { min: 86000, max: 95000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 40) {
        return {
            priceRange: { min: 96000, max: 110000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 45) {
        return {
            priceRange: { min: 120000, max: 150000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 50) {
        return {
            priceRange: { min: 160000, max: 190000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 55) {
        return {
            priceRange: { min: 195000, max: 220000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 60) {
        return {
            priceRange: { min: 220000, max: 230000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 65) {
        return {
            priceRange: { min: 240000, max: 290000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 70) {
        return {
            priceRange: { min: 250000, max: 290000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 75) {
        return {
            priceRange: { min: 300000, max: 320000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 80) {
        return {
            priceRange: { min: 320000, max: 350000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 85) {
        return {
            priceRange: { min: 340000, max: 370000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 90) {
        return {
            priceRange: { min: 350000, max: 380000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 95) {
        return {
            priceRange: { min: 370000, max: 400000 },
            applicationFee: 4500
        }
    }
    else if (numDwellings <= 100) {
        return {
            priceRange: { min: 390000, max: 420000 },
            applicationFee: 4500
        }
    }
    else {
        return {
            priceRange: { min: -1, max: -1 },
            applicationFee: -1
        }
    }
}

function assessAltReloc(poleType: PoleType): IndicativePrice {
    switch (poleType) {
        case "LV":
            return {
                priceRange: { min: 21000, max: 42000 },
                applicationFee: 2000
            }

        case "HV":
            return {
                priceRange: { min: 30000, max: 60000 },
                applicationFee: 3700
            }

        case "LV/HV":
            return {
                priceRange: { min: 35000, max: 70000 },
                applicationFee: 3700
            }

        case "PublicLighting":
            return {
                priceRange: { min: 20000, max: 40000 },
                applicationFee: 2000
            }

        case "66/22/LV":
            return {
                priceRange: { min: 45000, max: 90000 },
                applicationFee: 7000
            }

        default:
            return {
                priceRange: { min: -1, max: -1 },
                applicationFee: -1
            }
    }
}